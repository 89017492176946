/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Header from '../components/header';
import './layout.css';
import '../styles/theme.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Transition from '../components/transition';
import mobileHeaderStyles from '../styles/mobile.module.scss';

const Layout = ({ children, location }) => {
  const [menuActive, setMenuState] = useState(false);
  const handleClick = () => {
    setMenuState(!menuActive);
    if (menuActive) {
      document.body.style = 'overflow-y: scroll';
    } else {
      document.body.style = 'overflow-y: hidden';
    }
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="LayoutContainer">
        <div className={`TopNavBar ${menuActive ? 'nav-active' : ''}`}>
          <div className="nav-icon" onClick={() => handleClick()}>
            <div />
          </div>
        </div>
        <div className={`m-menu ${menuActive ? 'show' : ''}`}>
          <nav className={mobileHeaderStyles.navContainer}>
            <ul className={mobileHeaderStyles.navList}>
              <li>
                <Link
                  onClick={() => handleClick()}
                  className={mobileHeaderStyles.navItem}
                  activeClassName={mobileHeaderStyles.activeNavItem}
                  to="/"
                >
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => handleClick()}
                  className={mobileHeaderStyles.navItem}
                  activeClassName={mobileHeaderStyles.activeNavItem}
                  to="/school-projects/"
                >
                  SCHOOL PROJECTS
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => handleClick()}
                  className={mobileHeaderStyles.navItem}
                  activeClassName={mobileHeaderStyles.activeNavItem}
                  to="/real-projects/"
                >
                  REAL WORLD PROJECTS
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => handleClick()}
                  className={mobileHeaderStyles.navItem}
                  activeClassName={mobileHeaderStyles.activeNavItem}
                  to="/blogs/"
                >
                  BLOGS
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={() => handleClick()}
                  className={mobileHeaderStyles.navItem}
                  activeClassName={mobileHeaderStyles.activeNavItem}
                  to="/about/"
                >
                  ABOUT
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <Transition location={location}>{children}</Transition>
      </div>
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
