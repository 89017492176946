module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jimmy's Portfolio","short_name":"Portfolio","start_url":"/","background_color":"#4274b5","theme_color":"#4274b5","display":"minimal-ui","icon":"src/images/favicon.png","checkSupportedExtensions":false,"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"01c2fd5168d0619a97357f144001480d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
